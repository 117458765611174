import Ajax from './Ajax.js'

const Country = {
  getCountries: (id = null) => {
    let uri = id !== null
      ? '/countries' + '/' + id
      : '/countries'
    return Ajax.get(uri)
  },

  postCountries: (params) => {
    return Ajax.post('/countries', params)
  },

  putCountries: (params) => {
    return Ajax.put('/countries/' + params.id, params)
  },

  delCountries: (id) => {
    return Ajax.delete('/countries/' + id)
  }
}

export default Country
