<template>
  <div>
    <h3>New Forest</h3>

    <div class="mb-3">
      <b-button v-b-toggle.add-area-collapse variant="primary">+ Add</b-button>
    </div>

    <b-collapse id="add-area-collapse">
      <b-form>

        <!--        <b-form-group>-->
        <!--          <label>Import option</label>-->
        <!--          <b-form-select v-model="form.importType">-->
        <!--            <b-form-select-option value="new">New forest</b-form-select-option>-->
        <!--            <b-form-select-option value="old">Old forest</b-form-select-option>-->
        <!--          </b-form-select>-->
        <!--        </b-form-group>-->

        <!--        <b-form-group v-if="form.tree_type || form.importType == 'old'">-->
        <b-form-group>
          <label>Import Coordinates</label>
          <b-form-file
              v-model="form.files"
              :state="Boolean(form.files)"
              accept=".gpx,.geojson,.dbf,.shp,.kml,.csv"
              drop-placeholder="Drop file here..."
              multiple
              placeholder="Choose a file or drop it here..."
          ></b-form-file>
        </b-form-group>


        <b-form-group v-if="form.age == 'new'">
          <label>Tree Type <span class="text-danger">*</span></label>
          <b-form-select v-model="form.tree_type" :options="treeTypes" required></b-form-select>
        </b-form-group>

        <b-form-group>
          <label>Wallet Private Key <span class="text-danger">*</span></label>
          <b-input-group>
            <b-form-input
                v-model="form.private_key"
                maxlength="66"
                required
                type="text"
                @keyup="renderWallet"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" v-on:click="getWallet">New</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label>Wallet Address <span class="text-danger">*</span></label>
          <b-form-input
              v-model="form.wallet_address"
              disabled
              placeholder="Please enter the private key"
              required type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <div v-if="blocks">
            <label>Name</label>
            <br/>
            <small>
              <i class="fa-solid fa-circle-info"/>
              The block number will be added to the final name. For example, if you enter 'example' in the input, the final names will be 'example Block 1', 'example Block 2', and so on.</small>
          </div>
          <div v-else>
            <label>Name</label>
          </div>
          <b-form-input
              v-model="form.name"
              required
              type="text"
          ></b-form-input>
        </b-form-group>

        <img id="nft-image" ref="nft-image" class="mb-3" style="display: none">

        <b-form-group v-if="parsed.file">
          <b-button @click="getNFTImage">Get NFT Image</b-button>
        </b-form-group>

        <b-form-group v-if="blocks">
          <b-button @click="showAllNFTImages">See the NFT Images</b-button>
        </b-form-group>

        <b-form-group>
          <label>Image</label>
          <b-form-file
              v-model="form.image"
              :state="Boolean(form.image)"
              drop-placeholder="Drop image here..."
              placeholder="Choose an image or drop it here..."
          ></b-form-file>
        </b-form-group>


        <b-form-group>
          <label>Area in Ha</label>
          <div v-if="blocks"><small>Area size (in hectares) will be generated dynamically for each block</small></div>
          <b-form-input
              v-model="form.area_ha"
              min="0"
              required
              type="number"
              :disabled="blocks && blocks.length > 0"
          ></b-form-input>
        </b-form-group>

        <b-form-group v-if="false">
          <label>Blockchain Timestamp</label>
          <b-form-input
              v-model="form.year"
              min="1"
              required
              type="number"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <label>Country</label>
          <b-form-select v-model="form.country" :options="countries" required></b-form-select>
        </b-form-group>

        <b-form-group>
          <label>Owner</label>
          <b-form-select v-model="form.users_permissions_user" :options="users" required></b-form-select>
        </b-form-group>


        <b-form-group>
          <label>Cadastre</label>
          <b-form-input
              v-model="form.cadastre"
              type="text"
          ></b-form-input>
        </b-form-group>


        <b-form-group>
          <label>Cadastre certificate</label>
          <b-form-file
              v-model="form.cadastre_certificate"
              :state="Boolean(form.cadastre_certificate)"
              drop-placeholder="Drop file here..."
              placeholder="Choose a file or drop it here..."
          ></b-form-file>
        </b-form-group>

        <b-form-group>
          <label>Insurance certificate</label>
          <b-form-file
              v-model="form.insurance_certificate"
              :state="Boolean(form.insurance_certificate)"
              drop-placeholder="Drop file here..."
              placeholder="Choose a file or drop it here..."
          ></b-form-file>
        </b-form-group>

        <b-form-group>
          <label>Fertilizing certificate</label>
          <b-form-file
              v-model="form.fertilizing_certificate"
              :state="Boolean(form.fertilizing_certificate)"
              drop-placeholder="Drop file here..."
              placeholder="Choose a file or drop it here..."
          ></b-form-file>
        </b-form-group>

        <b-form-group v-if="false">
          <label>Url</label>
          <b-form-input
              v-model="form.url"
              type="text"
          ></b-form-input>
        </b-form-group>

        <b-button v-if="!blocks" :disabled="!parsed.isValid" @click.prevent="onSubmit">Submit</b-button>
        <b-button v-else @click.prevent="onSubmitWithBlocks">Submit Blocks</b-button>
      </b-form>

      <b-card v-if="this.parsed.isValid && this.parsed.file.length">
        <b-card-title>New area preview</b-card-title>
        <b-card-body>
          <ul>
            <li>isValid: {{ this.parsed.isValid }}</li>
            <li>Trees: {{ this.parsed.file.length }}</li>
          </ul>
        </b-card-body>
      </b-card>
    </b-collapse>
    <b-modal id="nft-image-modal" size="lg"  @shown="onImageModalShown">
      <template #modal-title>
        NFT image
      </template>
      <div v-if="!blocks">
        <div id="nft-image-box" ref="nft-image-box">
          <!--        <img id="nft-background" src="@/assets/img/zerticarbonnft.png" alt="">-->
          <MapBoxItemComponent :areaData="areaData"/>
        </div>
      </div>
      <div v-else>
        <div v-for="(block, idx) in currentChunk" :key="idx">
          <p class="mt-3">Block number: {{block.block}}</p>
          <div v-if="isValidAreaData(block.areaData)">
            <div :id="'nft-image-box-' + block.block" :ref="'nft-image-box-' + block.block">
              <MapBoxItemComponent :mapId="'nft-image-box-' + block.block" :areaData="block.areaData"/>
            </div>
          </div>
          <div v-else>
            <p>No Data</p>
            <br/>
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">

        <b-button v-if="!blocks" class="mr-2" variant="primary" @click="saveImage">
          Save
        </b-button>
        <b-button v-else class="mr-2" variant="primary" @click="showNextChunk">
          Save and Next
        </b-button>

        <b-button variant="secondary" @click="hide()">
          Close
        </b-button>
      </template>
    </b-modal>
    <b-modal id="select-tree-type-modal">
      <template #modal-title>
        Import file
      </template>
      <div v-if="importing">
        <p></p>
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
      <div v-else>
        <b-form-group>
          <label>Choose a tree type <span class="text-danger">*</span></label>
          <b-form-select v-model="form.tree_type" :options="treeTypes" required></b-form-select>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel, hide, importing }">
        <div v-if="!importing">
          <b-button class="mr-2" variant="primary" @click="importData">
            OK
          </b-button>
          <b-button variant="secondary" @click="hide()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="select-tree-types-modal">
      <template #modal-title>
        Import file
      </template>
      <div v-if="importing">
        <p></p>
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
      <div v-else>
      <b-form-group v-if="form.importType === 'csv' || form.importType === 'dbf'">
          <label>Scan date <span class="text-danger">*</span></label>
          <b-form-input
              v-model="form.datetime"
              required
              type="datetime-local"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <label>Choose tree types <span class="text-danger">*</span></label>
          <div v-if="!blocks">
            <b-input-group v-for="ref in Object.keys(form.file_tree_types)" v-bind:key="ref" class="mb-3">
              <template #prepend>
                <b-input-group-text>{{ form.file_tree_types[ref].reference }} -
                  {{ form.file_tree_types[ref].totalTrees }}
                  Trees
                </b-input-group-text>
              </template>
              <b-form-select v-model="form.tree_types[ref]" :options="treeTypes" required></b-form-select>
            </b-input-group>
          </div>
          <div v-else>

            <p>Enter the default values for trees 1, 2, and 3. You can edit them later. It is recommended to enter the values with the highest quantity of each to optimize the process.</p>
            <b-input-group
              v-for="item in [{ref: 1, name: 'Pine'}, {ref: 2, name: 'Spruce'}, {ref: 3, name: 'Birch'}]"
              :key="`${item.ref}-default`"
              class="mb-3"
            >
              <template #prepend>
                <b-input-group-text class="text-left">{{ item.ref }} - {{ item.name }}</b-input-group-text>
              </template>
              <b-form-select v-model="default_tree_types[item.ref]" :options="filteredTreeTypes(item.ref)" required></b-form-select>
            </b-input-group>

            <div class="d-flex justify-content-end">
              <b-button @click="selectAll" variant="primary" class="mb-5">Set this options as default</b-button>
            </div>

            <div v-for="(block, blockIndex) in blocks" :key="blockIndex">
              <p>Block {{ block.block }}</p>
              <b-input-group v-for="(treeData, idx) in block.file_tree_types" v-bind:key="idx" class="mb-3">
                <template #prepend>
                  <b-input-group-text>
                    {{ treeData.reference }} - {{ treeData.totalTrees }} Trees
                  </b-input-group-text>
                </template>
                <b-form-select v-model="block.selectedTreeTypes[treeData.reference]" :options="filteredTreeTypes(treeData.reference)" required></b-form-select>
              </b-input-group>
            </div>
          </div>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel, hide, importing }">
        <div v-if="!importing">
          <b-button class="mr-2" variant="primary" @click="importData">
            OK
          </b-button>
          <b-button variant="secondary" @click="hide()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="loading-modal" hide-footer no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        Importing file
      </template>
      <div class="d-flex justify-content-center my-4">
        <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </b-modal>
    <b-modal id="loading-img-modal" hide-footer no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        Creating NFT images
      </template>
      <div v-if="!blocks" class="d-flex justify-content-center my-4">
        <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div v-else class="my-4">
        <b-progress :value="progressNft" max="100" animated />
        <p class="mt-2">{{currentNft}} of {{blocks[blocks.length - 1].block}}</p>
      </div>
    </b-modal>
    <b-modal id="saving-blocks" hide-footer no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        Saving Blocks
      </template>

      <div class="my-4">
        <b-progress :value="progress" max="100" animated>
          <template #label>
            {{ progress }}%
          </template>
        </b-progress>
      </div>
    </b-modal>
    <b-modal id="nft-blocks-image" size="lg">
      <template #modal-title>
        Check your NFT's
      </template>
      <div v-for="(block, idx) in blocks" :key="idx" class="d-flex flex-column align-items-center mb-5">
        <p class="my-4 w-100 h6">Block number: {{block.block}}</p>
        <img style="width: 65%" :src="block.b64Image" />
      </div>
      <template #modal-footer="{ ok, cancel, hide }">

        <b-button class="mr-2" variant="primary" @click="modifyNFT">
          Modify
        </b-button>

        <b-button variant="secondary" @click="hide()">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import mergeImages from 'merge-images';
// import html2canvas from 'html2canvas';
import MapBoxItemComponent from '../../components/Area/MapBoxItemComponent'
import Checkpoint from '../../api/Checkpoint'
import Area from '../../api/Area'
import Country from '../../api/Country'
// import Sanitizer from "../../utils/Sanitizer";
import xml2js from 'xml2js'
import TreeType from "../../api/TreeType";
// import {create, all} from 'mathjs'
import {getCenterPoint} from "../../utils/calcCenterPoint";
import Swal from "sweetalert2";
import parseDBF from "parsedbf";
import transform from "transform-coordinates";
import * as shapefile from "shapefile";
import {ethers} from "ethers";
import Upload from "../../api/Upload";
import hull from 'hull.js'
import * as turf from '@turf/turf'
import {renderTreeTypeData} from "../../utils/renderTreeTypeData";
import User from "../../api/User";
import moment from "moment";
import Papaparse from "papaparse";
import * as tj from "@mapbox/togeojson";
// import moment from "moment";

export default {
  name: 'AreaAddComponent',

  components: {
    MapBoxItemComponent,
  },
  watch: {
    'form.files': {
      immediate: false,
      handler: function () {
        console.log(this.form.files, 'this.form.files1')
        const files = this.form.files
        if (files.length) {
          // read file workflow
          this.readFiles(files)

          // fill automatically the name

          let fullName = files[0].name.split('.')[0].replaceAll('_', ' ');

          let cadastre = ''
          let nameArr = []
          for (const word of fullName.split(' ')) {
            if (word.split('-').length - 1 >= 3) {
              cadastre = word
            }
            if (word.split('-').length == 1) {
              nameArr.push((word.charAt(0).toUpperCase() + word.slice(1)).trim())
            }
          }
          this.form.cadastre = cadastre;
          this.form.name = nameArr.join(' ');
        }
      }
    },
    'form.image': {
      immediate: false,
      handler: function () {
        console.log(this.form.image, 'this.form.image')
      }
    },
  },

  data() {
    return {
      importing: false,
      importingStatus: null,
      countries: [],
      users: [],
      treeTypes: [],
      areaData: {},
      form: {
        importType: null,
        age: 'old',
        files: null,
        fileExt: null,
        fileData: null,
        image: null,
        cadastre_certificate: null,
        insurance_certificate: null,
        fertilizing_certificate: null,
        name: '',
        private_key: '',
        wallet_address: '',
        country: 1,
        users_permissions_user: null,
        tree_type: null,
        tree_types: [],
        tree_types_ids: [],
        file_tree_types: {},
        cadastre: '',
        url: '',
        area_ha: 0,
        year: parseInt(new Date().getTime() / 1000, 10)
      },
      parsed: {
        file: null,
        isValid: false
      },
      blocks: null,
      currentChunkIndex: 0,
      blocksChunks: [],
      progress: 0,
      progressNft: 0,
      currentNft: 0,
      default_tree_types: {}
    }
  },
  created() {
    const emptyValue = {value: null, text: 'Please select an option'}
    Country.getCountries()
        .then((response) => {
          let countries = []
          countries.push(emptyValue)
          for (let item of response.data) {
            const element = {value: item.id, text: item.name}
            countries.push(element)
          }
          console.log({countries})
          this.countries = countries
        }).catch((error) => {
      console.log(error)
    })
    User.getUsers()
        .then((response) => {
          console.log({response})
          let users = []
          users.push(emptyValue)
          for (let item of response.data) {
            const element = {value: item.id, text: item.name}
            users.push(element)
          }
          this.users = users
        }).catch((error) => {
      console.log(error)
    })
    TreeType.getTreeTypes()
        .then((response) => {
          let treeTypes = []
          treeTypes.push(emptyValue)
          for (let item of response.data) {
            const element = {value: item, text: item.name}
            treeTypes.push(element)
          }
          this.treeTypes = treeTypes

          this.filteredTreeTypesMap = this.treeTypes.reduce((acc, treeType) => {
            if (treeType.value && treeType.value.reference) {
              if (!acc[treeType.value.reference]) {
                acc[treeType.value.reference] = [];
              }
              acc[treeType.value.reference].push(treeType);
            }
            return acc;
          }, {});

        }).catch((error) => {
      console.log(error)
    })
    this.getWallet()
  },
  computed: {
    currentChunk() {
      return this.blocksChunks[this.currentChunkIndex] || [];
    }
  },

  methods: {
    saveImage() {
      // console.log(document.querySelector('.mapboxgl-canvas'), "document.querySelector('.mapboxgl-canvas')")

      var resizedCanvas = document.createElement("canvas");
      var resizedContext = resizedCanvas.getContext("2d");

      resizedCanvas.height = "1035";
      resizedCanvas.width = "755";


      let mapEl = document.querySelector('.mapboxgl-canvas');

      resizedContext.drawImage(mapEl, 0, 0, 755, 1035);

      console.log(mapEl.toDataURL(), 'mapEl.toDataURL()')
      mergeImages([
            {
              src: resizedCanvas.toDataURL(),
              x: 297,
              y: 16
            },
            {
              src: require("@/assets/img/zerticarbonnft.png")
            },
            {
              src: require('@/assets/img/flags/finland.png'),
              x: 50,
              y: 905
            },

          ]
      )
          .then(b64 => {
            // let dataURL = this.dataURLtoFile(b64, 'image.png');
            // console.log({dataURL})
            console.log({b64})
            const myFile = this.dataURLtoFile(b64, 'image.png')
            console.log({myFile})
            // const dataTransfer = new DataTransfer();
            // dataTransfer.items.add(myFile);
            this.form.image = myFile;
            // if (this.form.image) {
            //   this.form.image.files = dataTransfer.files;
            // } else {
            //   this.form.image = {files: dataTransfer.files}
            // }
            console.log(this.form.image, 'this.form.image')
            this.$refs['nft-image'].src = b64;
            this.$refs['nft-image'].style.display = 'block';
            this.$bvModal.hide('nft-image-modal')

          });
      // mapEl.toBlob((blob) => {
      //    console.log(blob, 'blob1')
      //    console.log(URL.createObjectURL(blob), 'URL.createObjectURL(blob)')
      //
      //  })
      // html2canvas(document.body,{removeContainer: false}).then(canvas => {
      //   console.log({canvas})
      //   // document.body.appendChild(canvas)
      //   console.log(canvas.toDataURL(), 'canvas.toDataURL()')
      //   canvas.toBlob((blob) => {
      //     console.log(blob, 'blob2')
      //     console.log(URL.createObjectURL(blob), 'URL.createObjectURL(blob)2')
      //   })
      //
      //
      // });
    },
    saveMultipleImages() {
      var resizedCanvas = document.createElement("canvas");
      var resizedContext = resizedCanvas.getContext("2d");
      resizedCanvas.height = "1035";
      resizedCanvas.width = "755";

      let mapElements = document.querySelectorAll('.mapboxgl-canvas');

      for (const mapEl of mapElements) {
        resizedContext.drawImage(mapEl, 0, 0, 755, 1035);
        let parentArr = mapEl.parentNode.parentNode.parentNode.parentNode.querySelector('p').textContent.split(' ')

        let blockNumber = parentArr[parentArr.length - 1];

        if (!blockNumber) {
          continue
        }

        let currentBlock = this.blocks.find(b => b.block === Number(blockNumber));

        if (!currentBlock) {
          continue
        }

        mergeImages([
            {
              src: resizedCanvas.toDataURL(),
              x: 297,
              y: 16
            },
            {
              src: require("@/assets/img/zerticarbonnft.png")
            },
            {
              src: require('@/assets/img/flags/finland.png'),
              x: 50,
              y: 905
            },

          ]
        )
          .then(b64 => {
            const myFile = this.dataURLtoFile(b64, 'image.png')
            currentBlock.image = myFile;
            this.$set(currentBlock, 'b64Image', b64);
          });
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    getWallet() {
      const wallet = ethers.Wallet.createRandom();
      this.form.private_key = wallet.privateKey;
      this.form.wallet_address = wallet.address;
    },
    renderWallet() {
      this.form.wallet_address = '';
      if (this.form.private_key.length === 66) {
        try {
          const wallet = new ethers.Wallet(this.form.private_key)
          this.form.wallet_address = wallet.address;

        } catch (e) {
          Swal.fire('Error', e.message, 'error')
        }
      }
    },
    getNFTImage() {
      if (this.formIsValid()) {
        if (!this.blocks) {
          this.individualNFTblock()
        } else {
          this.multipleNFTblocks()
        }
      }
    },
    async onSubmit() {

      //   let a = 2;
      // if(1>a)
      // return;
      if (this.formIsValid()) {


        let image = null;
        if (this.form.image) {
          const formData = new FormData()
          formData.append('files', this.form.image, this.form.image.name)
          image = (await Upload.postUploads(formData)).data[0].id;
        }


        let cadastre_certificate = null;
        if (this.form.cadastre_certificate) {
          const formData = new FormData()
          formData.append('files', this.form.cadastre_certificate, this.form.cadastre_certificate.name)
          cadastre_certificate = (await Upload.postUploads(formData)).data[0].id;
        }


        let insurance_certificate = null;
        if (this.form.insurance_certificate) {
          const formData = new FormData()
          formData.append('files', this.form.insurance_certificate, this.form.insurance_certificate.name)
          insurance_certificate = (await Upload.postUploads(formData)).data[0].id;
        }


        let fertilizing_certificate = null;
        if (this.form.fertilizing_certificate) {
          const formData = new FormData()
          formData.append('files', this.form.fertilizing_certificate, this.form.fertilizing_certificate.name)
          fertilizing_certificate = (await Upload.postUploads(formData)).data[0].id;
        }


        // eslint-disable-next-line no-unreachable
        if (this.parsed.file && this.parsed.isValid) {

          // let treeTypes = this.parsed.file.treeTypes
          //                .map(treeType => {
          //                  return {
          //                    tree_type: treeType.id,
          //                    name: treeType.name,
          //                    qty: treeType.qty
          //                  };
          //                });

          let checkpoint = {
            image,
            datetime: this.form.datetime,
            data: this.parsed.file,
            qty: 0,
            CO2: 0,
            CO2Comparison: 0,
            lat: '',
            lng: '',
            height_average: 0,
            diameter_average: 0,
            theoretical_increment: 0,
            theoretical_stats: [...this.parsed.file.theoreticalStats] || []
          }

          console.log({checkpoint})
          // console.log({checkpoint})

          let coordinates = [];
          for (const treeType of this.parsed.file.treeTypes) {
            checkpoint.height_average += treeType.heightAverage / this.parsed.file.treeTypes.length;
            checkpoint.diameter_average += treeType.diameterAverage / this.parsed.file.treeTypes.length;
            checkpoint.qty += parseInt(treeType.qty);
            checkpoint.CO2 += parseFloat(treeType.CO2);
            checkpoint.CO2Comparison += parseFloat(treeType.CO2Comparison);
            coordinates = coordinates.concat(treeType.trees.map((tree) => [tree.lng, tree.lat]))
          }
          const centralPoint = getCenterPoint(coordinates)
          checkpoint.lng = centralPoint[0].toString();
          checkpoint.lat = centralPoint[1].toString();
          // debugger // eslint-disable-line no-debugger
          Checkpoint.postCheckpoints({
              CO2: checkpoint.CO2,
              CO2Comparison: checkpoint.CO2Comparison,
              data: checkpoint.data,
              datetime: checkpoint.datetime,
              diameter_average: checkpoint.diameter_average,
              image: checkpoint.image,
              lat: checkpoint.lat,
              lng: checkpoint.lng,
              qty: checkpoint.qty,
              theoretical_increment: checkpoint.theoretical_increment,
          })
              .then(async (checkpoint) => {

                let treeTypes = this.parsed.file.treeTypes
                    .map(treeType => {
                      return {
                        tree_type: treeType.id,
                        name: treeType.name,
                        qty: treeType.qty,
                        checkpoint: checkpoint.data.id
                      };
                    });

                await Checkpoint.postCheckpointTreeTypes(treeTypes);
                console.log(this.parsed.file, 'this.parsed.file')


                let theoreticalStats = this.parsed.file.theoreticalStats
                    .map(theoreticalStat => {
                      return {...theoreticalStat, checkpoint: checkpoint.data.id};
                    });

                  console.log(theoreticalStats, 'theoreticalStatstheoreticalStatstheoreticalStatstheoreticalStatstheoreticalStatstheoreticalStatstheoreticalStatstheoreticalStats')

                await Checkpoint.postTheoreticalStats(theoreticalStats);
                Area.postAreas({
                  name: this.form.name,
                  wallet_address: this.form.wallet_address,
                  private_key: this.form.private_key,
                  data: this.parsed.file,
                  image,
                  cadastre_certificate,
                  insurance_certificate,
                  fertilizing_certificate,
                  country: this.form.country,
                  users_permissions_user: this.form.users_permissions_user,
                  cadastre: this.form.cadastre,
                  url: this.form.url,
                  tree_types: this.form.tree_types_ids,
                  year: this.form.year,
                  area_ha: this.form.area_ha,
                  last_checkpoint: checkpoint.data.id,
                  checkpoints: [checkpoint.data.id]
                })
                    .then((area) => {
                      this.$router.push({path: '/areas-list'})
                      this.$toasted.show('The new area "' + area.data.name + '" has been added', {
                        type: 'success',
                        duration: 5000,
                        theme: "bubble",
                        position: "top-center"
                      });

                    }).catch((error) => {
                  console.log(error)
                })
              })


        }
      }
    },
    async onSubmitWithBlocks() {
      if(!this.formIsValidMultipleBlocks()) { // Wallet private key, wallet address, name, owner, cadastre
        return
      }

      this.$bvModal.show('saving-blocks')

      const totalBlocks = this.blocks.length;
      let processedBlocks = 0;

      for (const b of this.blocks) {
        if (b.importedJson?.treeTypes?.length === 0) {
          continue
        }

        const wallet = ethers.Wallet.createRandom();
        const private_key = wallet.privateKey;
        const wallet_address = wallet.address;

        let image = null;
        if (b.image) {
          const formData = new FormData()
          formData.append('files', b.image, 'image.png')
          image = (await Upload.postUploads(formData)).data[0].id;
        }

        let cadastre_certificate = null;
        if (this.form.cadastre_certificate) {
          const formData = new FormData()
          formData.append('files', this.form.cadastre_certificate, this.form.cadastre_certificate.name)
          cadastre_certificate = (await Upload.postUploads(formData)).data[0].id;
        }

        let insurance_certificate = null;
        if (this.form.insurance_certificate) {
          const formData = new FormData()
          formData.append('files', this.form.insurance_certificate, this.form.insurance_certificate.name)
          insurance_certificate = (await Upload.postUploads(formData)).data[0].id;
        }

        let fertilizing_certificate = null;
        if (this.form.fertilizing_certificate) {
          const formData = new FormData()
          formData.append('files', this.form.fertilizing_certificate, this.form.fertilizing_certificate.name)
          fertilizing_certificate = (await Upload.postUploads(formData)).data[0].id;
        }

        if (b.importedJson && b.parsedIsValid) {

          let checkpoint = {
            image,
            datetime: this.form.datetime,
            data: b.importedJson,
            qty: 0,
            CO2: 0,
            CO2Comparison: 0,
            lat: '',
            lng: '',
            height_average: 0,
            diameter_average: 0,
            theoretical_increment: 0,
            theoretical_stats: [...b.importedJson.theoreticalStats] || []
          }

          let coordinates = [];
          for (const treeType of b.importedJson.treeTypes) {
            checkpoint.height_average += treeType.heightAverage / b.importedJson.treeTypes.length;
            checkpoint.diameter_average += treeType.diameterAverage / b.importedJson.treeTypes.length;
            checkpoint.qty += parseInt(treeType.qty);
            checkpoint.CO2 += parseFloat(treeType.CO2);
            checkpoint.CO2Comparison += parseFloat(treeType.CO2Comparison);
            coordinates = coordinates.concat(treeType.trees.map((tree) => [tree.lng, tree.lat]))
          }
          const centralPoint = getCenterPoint(coordinates)
          checkpoint.lng = centralPoint[0].toString();
          checkpoint.lat = centralPoint[1].toString();
          // debugger // eslint-disable-line no-debugger
          Checkpoint.postCheckpoints({
            CO2: checkpoint.CO2,
            CO2Comparison: checkpoint.CO2Comparison,
            data: checkpoint.data,
            datetime: checkpoint.datetime,
            diameter_average: checkpoint.diameter_average,
            image: checkpoint.image,
            lat: checkpoint.lat,
            lng: checkpoint.lng,
            qty: checkpoint.qty,
            theoretical_increment: checkpoint.theoretical_increment,
          })
            .then(async (checkpoint) => {

              let treeTypes = b.importedJson.treeTypes
                .map(treeType => {
                  return {
                    tree_type: treeType.id,
                    name: treeType.name,
                    qty: treeType.qty,
                    checkpoint: checkpoint.data.id
                  };
                });

              await Checkpoint.postCheckpointTreeTypes(treeTypes);

              let theoreticalStats = b.importedJson.theoreticalStats
                .map(theoreticalStat => {
                  return {...theoreticalStat, checkpoint: checkpoint.data.id};
                });

              await Checkpoint.postTheoreticalStats(theoreticalStats);
              Area.postAreas({
                name: this.form.name + ' Block ' + b.block,
                wallet_address: wallet_address,
                private_key: private_key,
                data: b.importedJson,
                image,
                cadastre_certificate,
                insurance_certificate,
                fertilizing_certificate,
                country: this.form.country,
                users_permissions_user: this.form.users_permissions_user,
                cadastre: this.form.cadastre,
                url: this.form.url,
                tree_types: b.tree_types_ids,
                year: parseInt(new Date().getTime() / 1000, 10),
                area_ha: b.area_ha,
                last_checkpoint: checkpoint.data.id,
                checkpoints: [checkpoint.data.id]
              })
                .then((area) => {
                  processedBlocks++;

                  this.progress = Math.round((processedBlocks / totalBlocks) * 100);

                  this.$toasted.show('The new area "' + area.data.name + '" has been added', {
                    type: 'success',
                    duration: 2000,
                    theme: "bubble",
                    position: "bottom-center"
                  });

                }).catch((error) => {
                console.log(error)
              })
            })
        }
      }

      this.$bvModal.hide('saving-blocks')

      this.$router.push({path: '/areas-list'})

      // const firstBlock = this.blocks[0].block;
      // const lastBlock = this.blocks[this.blocks.length - 1].block;
      //
      // this.$toasted.show(`The new area from block ${firstBlock} to block ${lastBlock} has been added`, {
      //   type: 'success',
      //   duration: 5000,
      //   theme: "bubble",
      //   position: "top-center"
      // });

    },
    formIsValidMultipleBlocks() {
      if (!this.form.private_key) {
        Swal.fire('Error', 'Wallet Private Key is required', 'error')
        return false;
      }
      if (!this.form.wallet_address) {
        Swal.fire('Error', 'Wallet Address is required', 'error')
        return false;
      }
      if (!this.form.cadastre) {
        Swal.fire('Error', 'Cadastre is required', 'error')
        return false;
      }
      if (!this.form.users_permissions_user) {
        Swal.fire('Error', 'Owner is required', 'error')
        return false;
      }
      if (!this.form.name) {
        Swal.fire('Error', 'Name is required', 'error')
        return false;
      }

      return true;
    },
    formIsValid() {
      if (!this.form.private_key) {
        Swal.fire('Error', 'Wallet Private Key is required', 'error')
        return false;
      }
      if (!this.form.wallet_address) {
        Swal.fire('Error', 'Wallet Address is required', 'error')
        return false;
      }

      return true;
    },
    resetForm() {
      this.form.files = null
      this.form.image = null
      this.form.name = ''
      this.form.cadastre = ''
      this.form.url = ''
      this.parsed.file = null
      this.parsed.isValid = false
      this.getWallet()
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async importData() {


      try {
        console.log(this.form.importType, 'this.form.importType')
        let importedJSON;
        if (this.form.importType == 'gpx') {
          if (!this.form.tree_type) {
            this.form.files = null;
            Swal.fire('Error', 'Tree type is required', 'error')
          } else {
            this.$bvModal.hide('select-tree-type-modal')
            this.$bvModal.show('loading-modal')
            await this.sleep(1000)
            importedJSON = await this.xmlToJSON(this.form.fileData)
          }

        } else if (this.form.importType == 'csv') {
          console.log('CSV!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
          if (!this.form.tree_types) {
            this.form.files = null;
            Swal.fire('Error', 'Tree type is required', 'error')
          } else {
            this.$bvModal.hide('select-tree-types-modal');

            this.$bvModal.show('loading-modal')
            await this.sleep(1000)
            this.form.age = 'old';
            console.log('CSV')
            importedJSON = await this.renderCsvJSON(this.form.fileData)
            console.log({importedJSON})
            importedJSON.perimeters = [];
            for (const file of this.form.files) {
              const fileExt = file.name.split('.').at(-1);

              if (fileExt == 'kml') {

                let kml = new DOMParser().parseFromString(await this.readFileAsText(file), 'text/xml')

                console.log({kml})
                console.log({tj})
                let kmlData = tj.kml(kml);

                console.log({kmlData1: kmlData})


                for (const feature of kmlData.features) {
                  console.log({feature})
                  let value = feature.geometry;

                  console.log(value, 'value1');
                  console.log(value.type, 'value.type1');
                  if(value.type == "LineString") {
                    value.type = "Polygon";
                    value.coordinates = [value.coordinates];
                  }

                  if (value && value.type == "Polygon") {
                    value.area = Math.trunc(turf.area(value));
                    console.log(value.area, 'value.area');
                    this.form.area_ha += value.area / 10000;
                    importedJSON.perimeters.push(value)
                  }
                }


              }
            }

          }
        } else if (this.form.importType == 'geojson') {
          if (!this.form.tree_types) {
            this.form.files = null;
            Swal.fire('Error', 'Tree types are required', 'error')
          } else {
            this.$bvModal.hide('select-tree-types-modal');
            this.$bvModal.show('loading-modal')
            await this.sleep(1000)
            this.form.age = 'old';
            console.log('GEOJSON')
            importedJSON = await this.geojsonToJSON(this.form.fileData)

            importedJSON.perimeters = [];
            for (const file of this.form.files) {
              const fileExt = file.name.split('.').at(-1);

              if (fileExt == 'kml') {

                let kml = new DOMParser().parseFromString(await this.readFileAsText(file), 'text/xml')

                console.log({kml})
                console.log({tj})
                let kmlData = tj.kml(kml);

                console.log({kmlData2:kmlData})
                for (const feature of kmlData.features) {

                  let value = feature.geometry;

                  if(value.type == "LineString") {
                    value.type = "Polygon";
                    value.coordinates = [value.coordinates];
                  }

                  if (value && value.type == "Polygon") {
                    value.area = Math.trunc(turf.area(value));
                    this.form.area_ha += value.area / 10000;
                    importedJSON.perimeters.push(value)
                  }
                }


              }
            }

          }
        } else if (this.form.importType == 'dbf') {
          if (!this.form.tree_types) {
            this.form.files = null;
            Swal.fire('Error', 'Tree types are required', 'error')
          } else {

            // let test = this.$bvModal.hide('select-tree-types-modal');
            this.$bvModal.hide('select-tree-types-modal');
            this.$bvModal.show('loading-img-modal')
            await this.sleep(1000)
            this.form.age = 'old';
            if (!this.blocks) {
              importedJSON = await this.formatDronTreesInfo(this.form.fileData)
              importedJSON.perimeters = [];
              for (const file of this.form.files) {
                const fileExt = file.name.split('.').at(-1);
                if (fileExt == 'shp') {
                  let shpFile = await shapefile.openShp(await this.readFileAsBuffer(file));
                  let done = false
                  while (!done) {
                    let shpData = await shpFile.read();
                    let value = shpData.value

                    if (value && value.type == "Polygon") {
                      for (let coordinatesArr of value.coordinates) {
                        for (let coordinates of coordinatesArr) {
                          const {
                            lng,
                            lat
                          } = this.transformCoordinates(coordinates[0], coordinates[1])
                          coordinates[0] = lng;
                          coordinates[1] = lat;
                        }
                      }
                      value.area = Math.trunc(turf.area(value));
                      this.form.area_ha += value.area / 10000;
                      importedJSON.perimeters.push(value)
                    }

                    done = shpData.done;
                  }
                }
              }
            } else {
              const totalNftBlocks = this.blocks.length;
              let processedNftBlocks = 0;

              for (const block of this.blocks) {
                block.importedJson = await this.formatDronTreesInfoWithBlocks(block.trees, block.selectedTreeTypes)
                block.tree_types_ids = this.getTreeTypesIdsForBlocks(block.selectedTreeTypes)
                block.importedJson.perimeters = [];

                this.progressNftText(++processedNftBlocks, totalNftBlocks)
                console.log("block: ", block.block, " importedJSON: ", block.importedJson)
              }
            }


            // console.log({importedJSON})
          }
        }


        if(!this.blocks) {
          const perimeterCoordinates = this.getPerimeterCoordinates(importedJSON)
          const coordinatesArea = this.getCoordinatesArea(perimeterCoordinates)

          if (!this.form.area_ha) {
            this.form.area_ha = coordinatesArea;
          }

          this.parsed.file = importedJSON
          this.parsed.file.perimeterCoordinates = perimeterCoordinates;
          this.parsed.isValid = true

        } else {
          for (const block of this.blocks) {
            const perimeterCoordinates = this.getPerimeterCoordinates(block.importedJson)

            block.area_ha = this.getCoordinatesArea(perimeterCoordinates);
            block.parsedIsValid = true
            block.perimeterCoordinates = perimeterCoordinates
          }

          this.blocksChunks = this.chunkArrInBlocks(this.blocks, 12)

        }


      } catch (e) {
        console.error(e)
        Swal.fire('Error', e, 'error')
      }

      this.getNFTImage();
    },
    async readFiles() {
      console.log(this.form.files, 'this.form.file')
      this.files = this.form.files


      const supportedExtensions = ['gpx', 'geojson', 'dbf', 'csv'];
      let dataFile = null;
      for (const file of this.form.files) {
        const fileExt = file.name.split('.').at(-1);
        if (supportedExtensions.includes(fileExt)) {
          dataFile = {ext: fileExt, file};
          this.form.importType = fileExt;
          break;
        }
      }

      if (this.form.importType == 'dbf') {
        this.$bvModal.show('loading-modal')

        let data = [];
        let date = '';
        for (const file of this.form.files) {
          let parseDBFData = parseDBF(Buffer.from(await this.readFileAsBuffer(file)))
          console.log({parseDBFData})

          if (parseDBFData.length && (parseDBFData[0].species || parseDBFData[0].sp)) {
            data = parseDBFData;


          }

          if (parseDBFData.length && parseDBFData[0].order_id) {
            date = moment(parseDBFData[0].order_id, 'YYYY-WW').format('YYYY-MM-DD HH:mm:ss')
          }

        }


        if (data) {
          if (!date) {
            date = moment().format('YYYY-MM-DD HH:mm:ss')
          }
          data.map((res) => res['date'] = date)

          this.form.file_tree_types = {};
          for (const tree of data) {
            let reference = tree.species || tree.sp;

            this.form.file_tree_types[reference] = this.form.file_tree_types[reference] || {};
            const info = this.form.file_tree_types[reference];
            info.reference = reference;
            info.totalTrees = info.totalTrees ? info.totalTrees + 1 : 1;
            data.date = date;
          }

        }

        // data.perimeters = [];
        // for (const file of this.form.files) {
        //   const fileExt = file.name.split('.').at(-1);
        //   if (fileExt == 'shp') {
        //     let shpFile = await shapefile.openShp(await this.readFileAsBuffer(file));
        //     let shpData = await shpFile.read();
        //     let value = shpData.value
        //     console.log({value})
        //     if (value && value.type == "Polygon") {
        //       for (let coordinatesArr of value.coordinates) {
        //         for (let coordinates of coordinatesArr) {
        //           const {lng, lat} = this.transformCoordinates(coordinates[0], coordinates[1])
        //           coordinates[0] = lat;
        //           coordinates[1] = lng;
        //         }
        //       }
        //       data.perimeters.push(shpData.value)
        //     }
        //   }
        // }

        console.log({data})
        this.form.fileData = data;

        this.blocks = Object.keys(this.groupTreesByBlock(data)).length === 1
          ? null
          : this.groupTreesByBlock(data);

        if (this.blocks) {
          this.form.private_key = 'Private keys will be created dynamically for each block.'
          this.form.wallet_address = 'Wallet addresses will be created dynamically for each block.'
          this.form.area_ha = 0
        }

        this.$bvModal.hide('loading-modal')
        this.$bvModal.show('select-tree-types-modal');
        console.log(`this.$bvModal.show('select-tree-types-modal')`);

      } else if (this.form.importType == 'gpx') {
        console.log(dataFile.file, 'dataFile.file1')
        this.form.fileData = await this.readFileAsText(dataFile.file)
        this.$bvModal.show('select-tree-type-modal');

      } else if (this.form.importType == 'csv') {
        this.form.fileData = await this.readFileAsText(dataFile.file)
        let data = await this.csvToJSON(this.form.fileData);

        console.log({data})
        this.form.file_tree_types = {};
        for (const tree of data) {
          if (tree.type) {
            let reference = tree.type;
            this.form.file_tree_types[reference] = this.form.file_tree_types[reference] || {};
            const info = this.form.file_tree_types[reference];
            info.reference = reference;
            info.totalTrees = info.totalTrees ? info.totalTrees + 1 : 1;
          }
        }

        this.$bvModal.show('select-tree-types-modal');

      } else if (this.form.importType == 'geojson') {
        this.form.fileData = await this.readFileAsText(dataFile.file)
        const data = JSON.parse(this.form.fileData);
        this.form.file_tree_types = {};
        for (const tree of data.features) {
          // console.log(tree.properties, 'tree.properties')
          let treeTypeMeasurement = tree.properties.measurements?.filter((measurement) => measurement.name == 'Type tree')[0]
          if (treeTypeMeasurement) {
            let reference = treeTypeMeasurement.value;
            this.form.file_tree_types[reference] = this.form.file_tree_types[reference] || {};
            const info = this.form.file_tree_types[reference];
            info.reference = reference;
            info.totalTrees = info.totalTrees ? info.totalTrees + 1 : 1;
          }

        }

        this.$bvModal.show('select-tree-types-modal');

      } else {
        Swal.fire('Error', `We only accept ${supportedExtensions.join(', ')}`, 'error')
      }

    },
    formatDronTreesInfo(data) {
      return new Promise((resolve) => {
        for (const ref of Object.keys(this.form.tree_types)) {
          this.form.tree_types_ids.push(this.form.tree_types[ref].id)
        }

        let groupTreeTypes = {}
        let treeType = {};

        for (const tree of data) {
          // console.log({tree})
          let reference = tree.species || tree.sp;
          let datetime = tree.date;
          const {lng, lat} = this.transformCoordinates(tree["X"] || tree.x, tree["Y"] || tree.y)

          let height = parseFloat(tree.height_cor || tree.height || 0);
          let diameter = parseFloat(tree.dbh || 0);

          // treeType = treeType?.reference !== reference ? (await TreeType.getTreeTypesByRef(reference)).data[0] : treeType;

          treeType = treeType?.reference !== reference ? this.form.tree_types[reference] : treeType;


          if (treeType) {
            if (groupTreeTypes[reference] == undefined) {
              // if (treeType && treeType.id && !this.form.tree_types_ids.includes(treeType.id)) {
              //   this.form.tree_types_ids.push(treeType.id)
              // } else {
              //   this.$bvModal.hide('loading-modal')
              //   reject(`Could not find tree type with reference ${reference}`);
              //   return;
              // }

              if (!this.form.datetime) {
                this.form.datetime = moment(datetime).format('YYYY-MM-DD HH:mm:ss');
              }

              groupTreeTypes[reference] = {
                id: treeType.id,
                reference,
                name: treeType.name,
                densityKg: treeType.density_kg,
                practicalFormulas: treeType.practical_formulas,
                theoreticalAdditionalityFormulas: treeType.theoretical_additionality_formulas,
                theoreticalFormula: treeType.time_formula,
                trees: []
              }
            }

            groupTreeTypes[reference].trees.push({
              datetime,
              height,
              diameter,
              lng,
              lat
            })
          }
        }


        this.$bvModal.hide('loading-modal') // Això fora del metode?
        // console.log(`this.$bvModal.hide('loading-modal')`)
        resolve(renderTreeTypeData(Object.values(groupTreeTypes), this.form.datetime));
      });
    },
    formatDronTreesInfoWithBlocks(data, tree_types) {
      return new Promise((resolve) => {
        let groupTreeTypes = {}
        let treeType = {};

        for (const tree of data) {
          let reference = tree.species || tree.sp;
          let datetime = tree.date;
          const {lng, lat} = this.transformCoordinates(tree["X"] || tree.x, tree["Y"] || tree.y)

          let height = parseFloat(tree.height_cor || tree.height || 0);
          let diameter = parseFloat(tree.dbh || 0);

          treeType = treeType?.reference !== reference ? tree_types[reference] : treeType;

          if (treeType) {
            if (groupTreeTypes[reference] == undefined) {

              if (!this.form.datetime) {
                this.form.datetime = moment(datetime).format('YYYY-MM-DD HH:mm:ss');
              }

              groupTreeTypes[reference] = {
                id: treeType.id,
                reference,
                name: treeType.name,
                densityKg: treeType.density_kg,
                practicalFormulas: treeType.practical_formulas,
                theoreticalAdditionalityFormulas: treeType.theoretical_additionality_formulas,
                theoreticalFormula: treeType.time_formula,
                trees: []
              }
            }

            groupTreeTypes[reference].trees.push({
              datetime,
              height,
              diameter,
              lng,
              lat
            })
          }
        }

        this.$bvModal.hide('loading-modal')
        resolve(renderTreeTypeData(Object.values(groupTreeTypes), this.form.datetime));
      });
    },
    getTreeTypesIdsForBlocks(tree_types) {
      const current_tree_types_ids = []
      for (const ref of Object.keys(tree_types)) {
        current_tree_types_ids.push(tree_types[ref].id)
      }
      return current_tree_types_ids
    },
    transformCoordinates(oldLng, oldLat) {
      let lng = 0;
      let lat = 0;
      try {
        let trans = transform('EPSG:3067', 'EPSG:4326');
        const coors = trans.forward({x: oldLng, y: oldLat});
        lng = coors.x;
        lat = coors.y;
      } catch (error) {
        console.error(error)
        // console.log("error:", error)
      }
      return {lng, lat};
    },
    async readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async (res) => {
          console.log({res})
          resolve(res.target.result);
        }
        reader.onerror = (err) => reject(err)
        reader.readAsText(file);
      })
    },
    async readFileAsBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async (res) => {
          console.log({res})
          resolve(res.target.result);
        }
        reader.onerror = (err) => reject(err)
        reader.readAsArrayBuffer(file);
      })
    },
    renderCsvJSON(str) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {

        let trees = await this.csvToJSON(str);

        console.log({trees})
        for (const ref of Object.keys(this.form.tree_types)) {
          this.form.tree_types_ids.push(this.form.tree_types[ref].id)
        }

        let groupTreeTypes = {};
        let treeType = {};

        for (const tree of trees) {
          let treeTypeMeasurement = tree.type
          if (treeTypeMeasurement) {
            let reference = treeTypeMeasurement;
            let datetime = moment(this.form.datetime).format('YYYY-MM-DD HH:mm:ss');
            let lng = tree.lng;
            let lat = tree.lat;

            let height = parseFloat(tree.height ? tree.height : 0);
            let diameter = parseFloat(tree.diameter ? tree.diameter : 0);

            treeType = treeType?.reference !== reference ? this.form.tree_types[reference] : treeType;

            if (treeType) {
              if (groupTreeTypes[reference] == undefined) {


                this.form.datetime = moment(datetime).format('YYYY-MM-DD HH:mm:ss');

                groupTreeTypes[reference] = {
                  id: treeType.id,
                  reference,
                  name: treeType.name,
                  densityKg: treeType.density_kg,
                  practicalFormulas: treeType.practical_formulas,
                  theoreticalAdditionalityFormulas: treeType.theoretical_additionality_formulas,
                  theoreticalFormula: treeType.time_formula,
                  trees: []
                }
              }

              groupTreeTypes[reference].trees.push({
                datetime,
                height,
                diameter,
                lng,
                lat
              })
            }
          }
        }
        this.$bvModal.hide('loading-modal')

        console.log(this.form.datetime, 'this.form.datetime')
        resolve(renderTreeTypeData(Object.values(groupTreeTypes), this.form.datetime));
      })
    },
    csvToJSON(str) {
      return new Promise((resolve, reject) => {
        Papaparse.parse(str, {
          header: true,
          skipEmptyLines: 'greedy',
          complete: (res) => {
            if (res.error) {
              reject(res.error)
            } else {
              res.data = res.data.map((tree) => {
                return {
                  ...tree,
                  lng: parseFloat(tree.lng?.replaceAll(',', '.')),
                  lat: parseFloat(tree.lat?.replaceAll(',', '.')),
                }
              })
              resolve(res.data)
            }
          },
        })
      })
    },
    xmlToJSON(str, options) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        let treeType = {
          id: this.form.tree_type.id,
          reference: this.form.tree_type.reference,
          name: this.form.tree_type.name,
          densityKg: this.form.tree_type.density_kg,
          practicalFormulas: this.form.tree_type.practical_formulas,
          theoreticalAdditionalityFormulas: this.form.tree_type.theoretical_additionality_formulas,
          theoreticalFormula: this.form.tree_type.time_formula,
        };

        xml2js.parseString(str, options, (err, jsonObj) => {
          if (err || (!jsonObj.gpx && !jsonObj.gpx.wpt)) {
            return reject(err);
          }

          let trees = jsonObj.gpx.wpt.map((treeInfo) => {
            this.form.datetime = moment(treeInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');

            return {
              lat: treeInfo.$.lat,
              lng: treeInfo.$.lon,
              datetime: treeInfo.time[0],
              diameter: 0,
              height: 0,
            }
          })

          treeType.trees = trees;
          this.form.tree_types_ids = [this.form.tree_type.id];

          resolve(renderTreeTypeData([treeType], this.form.datetime));
        })
      });
    },
    geojsonToJSON(str) {
      const data = JSON.parse(str);
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        for (const ref of Object.keys(this.form.tree_types)) {
          this.form.tree_types_ids.push(this.form.tree_types[ref].id)
        }

        let groupTreeTypes = {}
        let treeType = {};

        for (const tree of data.features) {
          let treeTypeMeasurement = tree.properties.measurements?.filter((measurement) => measurement.name == 'Type tree')[0]
          if (treeTypeMeasurement) {
            let reference = treeTypeMeasurement.value;
            let datetime = tree.properties.measurementTime;
            let lng = tree.geometry.coordinates[0];
            let lat = tree.geometry.coordinates[1];
            let heightFilter = tree.properties.measurements.filter((measurement) => measurement.name === 'H/m')[0];
            let diameterFilter = tree.properties.measurements.filter((measurement) => measurement.name === 'D/cm')[0];
            let height = parseFloat(heightFilter ? heightFilter.value : 0);
            let diameter = parseFloat(diameterFilter ? diameterFilter.value : 0);

            // treeType = treeType?.reference !== reference ? (await TreeType.getTreeTypesByRef(reference)).data[0] : treeType;
            treeType = treeType?.reference !== reference ? this.form.tree_types[reference] : treeType;
            if (treeType) {
              if (groupTreeTypes[reference] == undefined) {

                this.form.datetime = moment(datetime).format('YYYY-MM-DD HH:mm:ss');

                groupTreeTypes[reference] = {
                  id: treeType.id,
                  reference,
                  name: treeType.name,
                  densityKg: treeType.density_kg,
                  practicalFormulas: treeType.practical_formulas,
                  theoreticalAdditionalityFormulas: treeType.theoretical_additionality_formulas,
                  theoreticalFormula: treeType.time_formula,
                  trees: []
                }
              }

              groupTreeTypes[reference].trees.push({
                datetime,
                height,
                diameter,
                lng,
                lat
              })
            }
          }
        }
        this.$bvModal.hide('loading-modal')
        resolve(renderTreeTypeData(Object.values(groupTreeTypes), this.form.datetime));
      });
    },
    getTreeType() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {


        this.$bvModal.msgBoxConfirm('Please select a tree type.', {
          title: 'Please Confirm',
          okTitle: 'OK',
          cancelTitle: 'Close',
          // footerClass: 'p-2',
          hideHeaderClose: true
        })
            .then(value => {
              if (value && this.form.tree_type) {
                this.form.age = 'new';
                resolve(this.form.tree_type);
              } else {
                this.form.age = 'old';
                this.form.files = null;
                reject('Tree type is required')
              }
            })
            .catch(err => {
              reject(err);
            })


      })
    },
    groupTreesByBlock(data) {
      return data
        .reduce((acc, tree) => {
          const block = tree.StandNumbe;

          // Si no existe el grupo para este bloque, lo creamos
          let blockGroup = acc.find(group => group.block === block);

          if (!blockGroup) {
            blockGroup = {block, trees: []};
            acc.push(blockGroup);
          }

          // Agregar el árbol al grupo correspondiente
          blockGroup.trees.push(tree);

          return acc;
        }, [])
        .sort((a, b) => a.block - b.block)
        .map(block => {
          // Crear el atributo file_tree_types para el bloque
          const file_tree_types = {};

          block.trees.forEach(tree => {
            const reference = tree.species || tree.sp;

            // Si la referencia no existe en file_tree_types, inicializarla
            if (!file_tree_types[reference]) {
              file_tree_types[reference] = {reference, totalTrees: 0};
            }

            // Incrementar el contador de totalTrees para la referencia
            file_tree_types[reference].totalTrees += 1;
          });

          // Asignar file_tree_types al bloque
          block.file_tree_types = file_tree_types;
          block.selectedTreeTypes = []
          return block;
        })
    },
    filteredTreeTypes(reference) {
      // return this.treeTypes.filter(treeType => treeType.value?.reference === reference.toString());
      return this.filteredTreeTypesMap[reference] || [];
    },
    getPerimeterCoordinates(importedJSON) {
      let perimeterCoordinates = [];

      let allAreaTreesCoordinates = [];
      for (const treeType of importedJSON.treeTypes) {
        // const treeType = importedJSON.treeTypes[ref];
        allAreaTreesCoordinates = allAreaTreesCoordinates
          .concat(
            treeType.trees
              .map(tree => [tree.lng, tree.lat])
          );
      }
      // console.log({allAreaTreesCoordinates})
      perimeterCoordinates = hull(
        allAreaTreesCoordinates,
        2)

      return perimeterCoordinates
    },
    getCoordinatesArea(perimeterCoordinates) {
      const coordinatesArea = +(turf.area({
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [
            perimeterCoordinates
          ]
        }
      }) / 10000).toFixed(2);

      return coordinatesArea
    },
    individualNFTblock() {
      // eslint-disable-next-line no-unreachable
      if (this.parsed.file && this.parsed.isValid) {
        console.log(this.parsed.file, 'this.parsed.file')
        this.areaData = {
          treeTypes: this.parsed.file.treeTypes,
          perimeters: this.parsed.file.perimeters
        }
        console.log("this.areaData: ", this.areaData)
        this.$bvModal.show('nft-image-modal')
        // let coordinates = [];
        // for (const treeType of this.parsed.file.treeTypes) {
        //
        //   coordinates = coordinates.concat(treeType.trees.map((tree) => [tree.lng, tree.lat]))
        // }
        // const centralPoint = getCenterPoint(coordinates)
        // checkpoint.lng = centralPoint[0].toString();
        // checkpoint.lat = centralPoint[1].toString();
        // debugger // eslint-disable-line no-debugger

      }
    },
    multipleNFTblocks() {
      for (const block of this.blocks) {
        if (block.importedJson && block.parsedIsValid) {
          this.$set(block, 'areaData', {
            treeTypes: block.importedJson.treeTypes,
            perimeters: block.importedJson.perimeters,
          });
        }

      }
      this.$bvModal.show('nft-image-modal')
    },
    isValidAreaData(areaData) {
      return areaData &&
        !(Array.isArray(areaData.centerPoint) && areaData.centerPoint.every(val => val === null)) &&
        !(Array.isArray(areaData.treeTypes) && areaData.treeTypes.length === 0); // &&
        // !(Array.isArray(areaData.perimeters) && areaData.perimeters.length === 0);
    },
    onImageModalShown() {
      this.$bvModal.hide('loading-img-modal')
    },
    chunkArrInBlocks(inputArr, size) {
      const outputArr = [];
      for (let i = 0; i < inputArr.length; i += size) {
        outputArr.push(inputArr.slice(i, i + size));
      }
      return outputArr;
    },
    showNextChunk() {
      this.saveMultipleImages()
      if (this.currentChunkIndex < this.blocksChunks.length - 1) {
        this.$bvModal.hide('nft-image-modal')
        this.currentChunkIndex++;
        this.$bvModal.show('nft-image-modal')
      } else {
        this.currentChunkIndex = 0;
        this.$bvModal.hide('nft-image-modal')
      }
    },
    showPreviousChunk() {
      if (this.currentChunkIndex > 0) {
        this.currentChunkIndex--;
      }
    },
    selectAll() {
      for (const block of this.blocks) {
        this.$set(block.selectedTreeTypes, 1, this.default_tree_types[1]);
        this.$set(block.selectedTreeTypes, 2, this.default_tree_types[2]);
        this.$set(block.selectedTreeTypes, 3, this.default_tree_types[3]);
      }
    },
    progressNftText(current, total) {
      this.progressNft = Math.round((current / total) * 100);
      this.currentNft = current;
    },
    showAllNFTImages() {
      this.$bvModal.show('nft-blocks-image')
    },
    modifyNFT() {
      this.$bvModal.show('nft-image-modal')
    }
  }
}

</script>

<style scoped>

</style>
