<template>
  <div>
    <h3>New Country</h3>

    <div class="mb-3">
      <b-button v-b-toggle.add-country-collapse variant="primary">+ Add</b-button>
    </div>

    <b-collapse id="add-country-collapse">
      <b-form>
        <b-form-group>
          <label>Name</label>
          <b-form-input
            v-model="form.name"
            required
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-button @click.prevent="onSubmit">Submit</b-button>
      </b-form>
    </b-collapse>
  </div>
</template>

<script>
import Country from "../../api/Country"

export default {
  name: 'CountryAddComponent',

  data() {
    return {
      form: {
        name: ''
      }
    }
  },

  methods: {
    onSubmit() {
      Country.postCountries({
        name: this.form.name
      })
        .then((response) => {
          this.resetForm()
          this.$toasted.show('The new country "' + response.data.name + '" has been added', {
            type: 'success',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        }).catch((error) => {
        console.log(error)
      })
    },

    resetForm() {
      this.form.name = ''
    }
  }
}
</script>

<style scoped>

</style>
