<template>
  <b-container class="admin py-5">
    <b-row>
      <b-col
        v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'">
        <h1>Admin</h1>

        <b-card>
          <h3>Balance statistics</h3>
          <div class="table-responsive pb-4">
            <table class="table table-striped">
              <tr>
                <td>Forests</td>
                <td>CCO2 Tons available</td>
              </tr>
              <tr>
                <td>{{new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(stats.areas)}}</td>
                <td>{{new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(stats.balance)}}</td>
              </tr>
            </table>
          </div>


          <AreaAddComponent/>

          <!--          <hr />-->

          <!--          <TreeTypeAddComponent />-->

          <hr/>

          <CountryAddComponent/>
        </b-card>
      </b-col>
      <b-col v-else>
        <p>You should not be here!</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AreaAddComponent from "../components/Admin/AreaAddComponent"
import {mapGetters} from "vuex";
// import TreeTypeAddComponent from "../components/Admin/TreeTypeAddComponent";
import CountryAddComponent from "../components/Admin/CountryAddComponent";
import Stats from "../api/Stats";

export default {
  name: 'Admin',
data() {
    return {
      stats: null
    }
  },
  created() {
    Stats.getAdminStats()
      .then((stats) => {
        this.stats = stats.data
      }).catch((error) => {
      console.log(error)
    })
  },
  components: {
    CountryAddComponent,
    // TreeTypeAddComponent,
    AreaAddComponent
  },

  computed: {
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"])
  }
}
</script>

<style scoped>

</style>
